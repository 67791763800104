import React, { useState, useEffect, useCallback } from "react";
import SEO from "../../services/seo";
import instance from '../../services/axios';
import requests from '../../services/requests';
import Banner from "../Banner";
import Row from "../Row";
import BookmarkRow from "../BookmarkRow";
import GamesList from "../GamesList";

function Home() {
    const [genres, setGenres] = useState([]);
    const [offset, setOffset] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const limit = 9;

    const fetchGenres = useCallback(async () => {
        try {
            setIsFetching(true);
            const response = await instance.get(`${requests.getBookGenre}&limit=${limit}&offset=${offset}`);
            const data = response.data;
            setGenres(prevGenres => [...prevGenres, ...data]);
            setIsFetching(false);
        } catch (error) {
            console.error('Error fetching genres:', error);
            setIsFetching(false);
        }
    }, [offset]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 && !isFetching) {
                setOffset(prevOffset => prevOffset + limit);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll); // Clean up event listener
    }, [isFetching]);

    useEffect(() => {
        fetchGenres();
    }, [fetchGenres]);
        
    return (
        <>
            <SEO
                title='Home - Bibliophile'
                description='Discover a wide range of books across various genres including classics, fantasy, romance, and non-fiction on Bibliophile.'
                name='Bibliophile'
                type='website'
                image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
            />
            <Banner />

            <Row title= "Classics" />
            <BookmarkRow />
            <Row title= "Fantasy" />
            <GamesList />
            <Row title= "Romance" />
            <Row title= "Historical Fiction" />

            {/* Render genres dynamically */}
            {genres.length === 0 && !isFetching && <p className="genre-loading-text">No genres available at the moment.</p>}

            {genres.map((genre) => (
                !["Classics", "Fantasy", "Romance", "Historical Fiction"].includes(genre.genre) && (
                    <Row title={genre.genre} />
                )
            ))}

            {/* Loading spinner/message */}
            {isFetching && <p className="genre-loading-text">Loading more genres...</p>}
        </>
    );
  }
  
  export default Home;