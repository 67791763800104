import React, { useContext, useState } from 'react';
import { FaUser, FaLock, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ClipLoader } from "react-spinners"; // Import a spinner component
import axios from "../services/axios";
import requests from "../services/requests";
import SEO from '../services/seo';
import { UserContext } from '../contexts/UserContext'; 
import './SignupLogin.css';
import Mascot from './Mascot';

function SignupLogin() {
    const [isRegistration, setIsRegistration] = useState(false);
    const [loginEmail , setLoginEmail] = useState('');
    const [loginPass , setLoginPass] = useState('');
    const [signupName , setSignupName] = useState('');
    const [signupEmail, setSignupEmail] = useState('');
    const [signupPhone , setSignupPhone] = useState('');
    const [signupPass , setSignupPass] = useState('');
    const [signupPassCnf, setSignupPassCnf] = useState('');
    const [source, setSource] = useState(null);
    const [successMessageVisibility, setSuccessMessageVisibility] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state

    const [eyesOpen, setEyesOpen] = useState(true);

    const {  user, setUser } = useContext(UserContext);

    function toggleRegistration() {
        isRegistration ? setIsRegistration(false) : setIsRegistration(true); 
    }

    // Handle input changes
    const handleLoginEmail = (e) => setLoginEmail(e.target.value);
    const handleLoginPass = (e) => setLoginPass(e.target.value);
    const handleSignupName = (e) => setSignupName(e.target.value);
    const handleSignupEmail = (e) => setSignupEmail(e.target.value);
    const handleSignupPhone = (e) => setSignupPhone(e.target.value);
    const handleSignupPass = (e) => setSignupPass(e.target.value);
    const handleSignupPassCnf = (e) => setSignupPassCnf(e.target.value);
    const handleSourceChange = (e) => setSource(e.target.value);

    const handleSignup = () => {
        if (!signupName || !signupEmail || !signupPhone || !signupPass || !signupPassCnf || !source)
        {
            alert("Please fill all the details!");
        }
        else if (signupPass !== signupPassCnf) {
            alert("Passwords don't match");
        }
        else {
            setLoading(true); // Start loading
            async function fetchData() {
                try {
                    const response = await axios.post(requests.userSignup, {
                        name: signupName,
                        email: signupEmail,
                        phone: signupPhone,
                        password: signupPass,
                        signupPassCnf: signupPassCnf,
                        source: source,
                    });
                    if (response.data.message === 1) {
                        setSuccessMessageVisibility(true);
                        setSignupName("");
                        setSignupEmail("");
                        setSignupPhone("");
                        setSignupPass("");
                        setSignupPassCnf("");
                        setSource(null);
                    } else {
                        alert(response.data.message);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoading(false); // End loading
                }
            }
            fetchData();
        }
    }

    const handleLogin = () => {
        if (!loginEmail || !loginPass)
        {
            alert("Please fill all the details!");
        }
        else {
            setLoading(true); // Start loading
            async function fetchData() {
                try {
                    const response = await axios.post(requests.userLogin, {
                        email: loginEmail,
                        pass: loginPass,
                    });
                    if (response.data.message === 1) {
                        localStorage.setItem('user', response.data.userId);
                        setUser(response.data.userId);
                    } else {
                        alert(response.data.message);
                        console.log(response);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoading(false); // End loading
                }
            }
            fetchData();
        }
    }

    const forgotPassword = () => {
        if (!loginEmail)
        {
            alert("Please fill the email address");
        }
        else {
            setLoading(true); // Start loading
            async function fetchData() {
                try {
                    const response = await axios.post(requests.forgotPassword, {
                        email: loginEmail,
                    });
                    alert(response.data.message);
                } catch (error) {
                    alert("There was an error! Please try again.");
                } finally {
                    setLoading(false); // End loading
                }
            }
            fetchData();
        }
    }

    return (
        <div className='wrapper'>
            <SEO
                title={isRegistration ? 'Sign Up - Bibliophile' : 'Login - Bibliophile'}
                description={isRegistration ? 'Create a new Bibliophile account to start your journey into the world of books.' : 'Log in to your Bibliophile account to explore and enjoy our vast collection of books.'}
                name='Bibliophile'
                type='website'
                image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
            />
            <Mascot emotion={eyesOpen ? "welcome" : "eyesClosed"}/>
            {!isRegistration ? <div>
                <h1>Login</h1>
                <div className='input-box'>
                    <input type='email' placeholder='Email' required value={loginEmail} onChange={(e) => { handleLoginEmail(e); } }/>
                    <FaUser className='icon'/>
                </div>
                <div className='input-box'>
                    <input type='password' placeholder='Password' required onClick={()=>setEyesOpen(false)} onBlur={()=>setEyesOpen(true)} value={loginPass} onChange={(e) => { handleLoginPass(e); } }/>
                    <FaLock className='icon'/>
                </div>

                <div className='remember-forgot'>
                    <label><input type='checkbox' checked/>Remember me</label>
                    <a href='#' onClick={() => forgotPassword()}>Forgot password?</a>
                </div>

                <button onClick={handleLogin} disabled={loading}>
                    {loading ? <ClipLoader color="#fff" size={20} /> : "Login"}
                </button>

                <div className='register-link'>
                    <p>Don't have an account? <a href='#' onClick={toggleRegistration}>Register</a></p>
                </div>
            </div>
            :
            <div>
                 {successMessageVisibility && <h2 className="signupSuccessMessage">Signup successful!</h2>}
                <h1>Sign up</h1>
                <div className='input-box'>
                    <input type='text' placeholder='Username' required value={signupName} onChange={(e) => { handleSignupName(e); } } />
                    <FaUser className='icon'/>
                </div>
                <div className='input-box'>
                    <input type='email' placeholder='Email id' required value={signupEmail} onChange={(e) => { handleSignupEmail(e); } } />
                    <MdEmail className='icon'/>
                </div>
                <div className='input-box'>
                    <input type='tel' pattern='[0-9]{10}' placeholder='Phone' required value={signupPhone} onChange={(e) => { handleSignupPhone(e); } } />
                    <FaPhone className='icon'/>
                </div>
                <div className='input-box'>
                    <input type='password' placeholder='Password' required onClick={()=>setEyesOpen(false)} onBlur={()=>setEyesOpen(true)} value={signupPass} onChange={(e) => { handleSignupPass(e); } } />
                    <FaLock className='icon'/>
                </div>
                <div className='input-box'>
                    <input type='password' placeholder='Confirm password' required onClick={()=>setEyesOpen(false)} onBlur={()=>setEyesOpen(true)} value={signupPassCnf} onChange={(e) => { handleSignupPassCnf(e); } } />
                    <FaLock className='icon'/>
                </div>
                <h2>How did you find us?</h2>
                <div className='input-box'>
                    <select value={source} onChange={handleSourceChange} required>
                    <option value={null}>Select an option</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Word of Mouth">Friends/Word of Mouth</option>
                    <option value="Online Ads">Online Ads</option>
                    <option value="App Store">App Store</option>
                    <option value="Forums or Online Communities">Influencer/Online Communities</option>
                    <option value="Print Media">Print Media</option>
                    <option value="Other">Other</option>
                    </select>
                </div>

                <button onClick={handleSignup} disabled={loading}>
                    {loading ? <ClipLoader color="#fff" size={20} /> : "Sign up"}
                </button>

                <div className='register-link'>
                    <p>Already have an account? <a href='#' onClick={toggleRegistration}>Login</a></p>
                </div>
            </div>
            }
        </div>
    );
}

export default SignupLogin;
