import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import instance from '../../services/axios';
import requests from '../../services/requests';
import './NoteList.css';
import SEO from '../../services/seo';
import Mascot from '../Mascot';

const NoteList = () => {
    const [notes, setNotes] = useState([]);
    const [editing, setEditing] = useState(null);
    const [currentNote, setCurrentNote] = useState('');
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observerRef = useRef();

    const userId = localStorage.getItem('user');
    const notesLimit = 10;

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                // const userTimezone = 'Asia/Kolkata'; // Replace this with actual timezone logic
                const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const response = await instance.get(`${requests.fetchUserNotes}`, {
                    params: {
                        userId,
                        offset,
                        limit: notesLimit,
                        timezone: userTimezone,
                    },
                });
                const fetchedNotes = response.data;
                if (fetchedNotes.length < notesLimit) {
                    setHasMore(false);
                }
                setNotes(prevNotes => {
                    const prevNoteIds = new Set(prevNotes.map(note => note.noteId));
                    const uniqueNotes = fetchedNotes.filter(note => !prevNoteIds.has(note.noteId));
                    return [...prevNotes, ...uniqueNotes];
                });
            } catch (error) {
                console.error("Error fetching notes:", error);
            }
        };

        fetchNotes();
    }, [offset, userId]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    setOffset(prevOffset => prevOffset + notesLimit);
                }
            },
            { threshold: 1.0 }
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasMore]);

    const handleEdit = (note) => {
        setEditing(note.noteId);
        setCurrentNote(note.note);
    };

    const handleDelete = (noteId) => {
        const confirmed = window.confirm("Are you sure you want to delete this note?");
        if (confirmed) {
            instance.post(requests.updateUserNote, {
                userId,
                noteId,
                actionType: 'delete'
            })
                .then(response => {
                    setNotes(notes.filter(note => note.noteId !== noteId));
                    alert(response.data.message);
                })
                .catch(error => console.error("Error deleting note:", error));
        }
    };

    const handleSave = (noteId) => {
        instance.post(requests.updateUserNote, {
            userId,
            noteId,
            note: currentNote,
            actionType: 'update'
        })
            .then(response => {
                setNotes(notes.map(note =>
                    note.noteId === noteId ? { ...note, note: currentNote } : note
                ));
                setEditing(null);
                alert(response.data.message);
            })
            .catch(error => console.error("Error updating note:", error));
    };

    const handleChange = (value) => {
        setCurrentNote(value);
    };

    return (
        <>
            <SEO
                title="Your notes - Biblophile"
                description="View the daily notes you have written."
                name='Biblophile'
                type='website'
                image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
            />
            <div className="user-notes">
                <h2 className="title">My Notes</h2>
                {notes.length === 0 && <Mascot emotion="reading"/>}
                {notes.map((note) => (
                    <div key={note.noteId} className="note-card">
                        {editing === note.noteId ? (
                            <>
                                <textarea
                                    name="note"
                                    value={currentNote}
                                    onChange={handleChange}
                                    className="note-input"
                                />
                                <button onClick={() => handleSave(note.noteId)} className="save-btn">Save</button>
                            </>
                        ) : (
                            <>
                                <div className="note-content">
                                    <NavLink to={`/product/?type=Book&id=${note.bookId}`} className="product_link">
                                        <img src={note.bookPhoto} alt={note.bookName} className="thumbnail" />
                                    </NavLink>
                                    <div className="note-text-container">
                                        <p className="note-text">{note.note}</p>
                                        <p className="updatedAt-text">{note.updatedAt}</p>
                                    </div>
                                </div>
                                <button onClick={() => handleEdit(note)} className="edit-btn">Edit</button>
                                <button onClick={() => handleDelete(note.noteId)} className="delete-btn">Delete</button>
                            </>
                        )}
                    </div>
                ))}
                {hasMore && <div ref={observerRef} className="loading">Loading more notes...</div>}
            </div>
        </>
    );
};

export default NoteList;