// HtmlFormat.js
import React from 'react';

export const HtmlFormat = (description) => {
    if (!description) return null;

    // Split the description by HTML tags
    const parts = description.split(/(<\/?b>|<\/?i>|<p>|<\/p>|<br\s*\/?>)/g);

    return parts.map((part, index) => {
        switch (part) {
            case '<b>':
                return <strong key={index} />;
            case '</b>':
                return null;
            case '<i>':
                return <em key={index} />;
            case '</i>':
                return null;
            case '<br>':
            case '<br />':
                return <br key={index} />;
            case '<p>':
                return <p key={index} style={{ margin: '0.5em 0' }}>; {/* Add margin for spacing */} </p>;
            case '</p>':
                return null;
            default:
                return part;
        }
    });
};
