import React, { useState, useEffect } from 'react';
import SEO from "../../services/seo";
import { FaFacebookF, FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import instance from "../../services/axios";
import requests from '../../services/requests';

import './About.css';

const About = () => {
  const [currentReads, setCurrentReads] = useState({});

  const fetchCurrentReads = async (userId) => {
    try {
      const response = await instance.post(requests.fetchCurrentReads, {
        userId: userId,
      });
      const books = response.data.currentReads;
      let currentlyReading = 'nothing';

      if (books.length > 0) {
        currentlyReading = books.map(book => ({
          name: book.BookName,
          id: book.BookId
        }));
      }

      return currentlyReading;
    } catch (error) {
      console.error('Failed to fetch current reads:', error);
      return 'nothing';
    } 
  };

useEffect(() => {
  const fetchReadsForTeam = async () => {
    const debarshiReads = await fetchCurrentReads(1);
    const rashmiReads = await fetchCurrentReads(7);

    setCurrentReads({
      Debarshi: debarshiReads,
      Rashmi: rashmiReads,
    });
  };

  fetchReadsForTeam();
}, []);

  return (
    <>
      <SEO
        title="Introducing Biblophile: Your Gateway to a World of Books"
        description="Discover Biblophile, a platform dedicated to connecting readers with a vast collection of books and insightful content."
        name='Biblophile'
        type='article'
        image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
      />
      <div className="about-container">
        <section className="company-mission">
          <h2>Our Mission</h2>
          <p>At Biblophile, we are passionate about making reading more accessible and enjoyable for everyone. We believe in the 
            magic of books and strive to bring that magic right to your doorstep. Our mission is simple: to eliminate the hassle of 
            buying, storing, and managing your book collection. With our services, you can rent, read, and return books 
            with ease—creating your own personal library at home.
            We also offer insightful reading stats and analytics to enhance your reading experience, 
            helping you discover more about your reading habits and preferences, and help you find your next read. Join us in our 
            mission to make reading more convenient, fun, and insightful for all.</p>
        </section>

        <section className="products-services">
          <h2>Products & Services</h2>
          <p>We offer a range of products and services designed to enhance your reading experience:</p>
          <ul>
            <li><p><strong>Buying and Renting Books:</strong> Convenient options to either add to your collection or enjoy books without the commitment of ownership.</p></li>
            <li><p><strong>Smart Bookmarks for Reading Streaks:</strong> Innovative bookmarks that help you track your reading progress and maintain your streaks.</p></li>
            <li><p><strong>Reading Stats and Insights:</strong> Beautiful graphical interpretations of your reading habits to provide deeper insights and enrich your reading journey.</p></li>
          </ul>
          <p>Our goal is to provide a seamless and engaging experience that makes managing and enjoying your reading adventures easier and more rewarding.</p>
        </section>

        <section className="team-members">
          <h2>Meet the Team</h2>
          <div className="team-grid">
            <div className="team-member">
              <img src="https://media.istockphoto.com/id/1097490360/vector/vector-illustration-of-cute-black-cat.jpg?s=612x612&w=0&k=20&c=Ef0qYl79aZJ6NJXJVbJ0onjXVNnSyqrN_TKPjieAIGE=" alt="Team Member 1" />
              <h3>Debarshi Das</h3>
              <p>Maintains the site, app and other technical aspects.</p>
              <p>
                Currently reading {Array.isArray(currentReads.Debarshi) ? (
                  currentReads.Debarshi.map((book, index) => (
                    <React.Fragment key={book.id}>
                      <a href={`/product/?type=Book&id=${book.id}`} target="_blank" rel="noopener noreferrer">
                        {book.name}
                      </a>
                      {index < currentReads.Debarshi.length - 1 && ', '}
                    </React.Fragment>
                  ))
                ) : 'nothing'}.
              </p>
              <p>Likes to sleep in free time.</p>
            </div>
            <div className="team-member">
              <img src="https://img.freepik.com/premium-vector/cute-cartoon-cat-vector-illustration-isolated-white-background_1151-48146.jpg" alt="Team Member 2" />
              <h3>Rashmi Ramesh</h3>
              <p>Looks after design, social media and operations.</p>
              <p>
                Currently reading {Array.isArray(currentReads.Rashmi) ? (
                  currentReads.Rashmi.map((book, index) => (
                    <React.Fragment key={book.id}>
                      <a href={`/product/?type=Book&id=${book.id}`} target="_blank" rel="noopener noreferrer">
                        {book.name}
                      </a>
                      {index < currentReads.Rashmi.length - 1 && ', '}
                    </React.Fragment>
                  ))
                ) : 'nothing'}.
              </p>
              <p>Likes to chatter in free time.</p>
            </div>
            {/* Add more team members as needed */}
          </div>
        </section>

        <section className="blog-section">
          <h2>Our Blog</h2>
          <p>Check out our latest articles and insights on our <a href="https://biblophile.com/blog/2023/11/13/introducing-biblophile-your-gateway-to-a-world-of-books/" target="_blank" rel="noopener noreferrer">blog</a>.</p>
          <div className="blog-glimpse">
            <iframe
              src="https://biblophile.com/blog/2023/11/13/introducing-biblophile-your-gateway-to-a-world-of-books/"
              title="Biblo blog"
              width="100%"
              height="400px"
              style={{ border: 'none', borderRadius: 'var(--radius-10)' }}
              loading="lazy"
            ></iframe>
          </div>
        </section>

        <section className="fun-facts">
          <h2>Fun Company Facts</h2>
          <ul>
            <li><p>Did you know? The name "Biblophile" came about in a rather unconventional way—during a late-night search for a domain name, a typo led to our unique name. We were so thrilled to snag the domain that we decided to keep the playful misspelling, and it’s become a charming part of our identity!</p></li>
            <li><p>We’re a dynamic duo here at Biblophile. One of us focuses on coding and the technical wizardry behind our platform, while the other handles design, social media, and operations. Our teamwork ensures a seamless experience for our readers, blending technical expertise with creative flair.</p></li>
            <li><p>Here’s a quirky touch: we’ve incorporated a “Book Discovery Challenge” into our service, where each month, we feature a surprise book genre or theme to ignite your curiosity and expand your reading horizons. Plus, our smart bookmarks not only track your reading streak but also come with customizable motivational quotes to keep you inspired!</p></li>
            <li><p>At Biblophile, we believe in making reading not just a habit, but a delightful adventure. Our culture is all about embracing the unexpected and enjoying the journey—whether that’s through our playful name, our unique services, or the way we connect with our community.</p></li>
          </ul>
        </section>

        <section className="contact-info">
          <h2>Contact Us</h2>
          <p>Email: <a href="mailto:[your-email]">help@biblophile.com</a></p>
          <p>Phone: +91 96063 73974</p>
          <p>Facebook:
            <a href="https://www.facebook.com/profile.php?id=61559661155321" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
          </p>
          <p>Instagram:
            <a href="https://www.instagram.com/__biblophile__/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </p>
          <p>X/Twitter:
            <a href="https://x.com/__biblophile__" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
          </p>
        </section>
      </div>
    </>
  );
};

export default About;


