import React, { useState, useEffect } from "react";
import './Banner.css';

const banners = [
    {
        image: "https://images.unsplash.com/photo-1587397070638-81d3cce10435?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
        title: "Hey there!",
        description: "We are a team of book enthusiasts trying to make reading a bit more accessible and a bit more fun by getting it delivered to you at your address. No more buying and storing of books. Rent it, read it, return it. Library at home."
    },
    // {
    //     image: "https://i.postimg.cc/hGb9vrts/1.png",
    //     title: "",
    //     description: ""
    // },
    // {
    //     image: "https://i.postimg.cc/JnrjkkyL/2.png",
    //     title: "",
    //     description: ""
    // },
    // Add more banner objects here
];

/**
 * Represents a banner component.
 * @throws {Error} If there is an issue with rendering the banner.
 */
function Banner() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % banners.length);
        }, 5000); // Change banner every 5 seconds

        return () => clearInterval(intervalId);
    }, []);

    const { image, title, description } = banners[currentIndex];

    return (
        <header className="banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `url(${image})`,
                backgroundPosition: "center center",
            }}
        > 
            <div className="banner_contents">
                <h1 className="banner_title">{title}</h1>
                <h1 className="banner_description">{description}</h1>
            </div>
            <div className="banner_fadeBottom" />
        </header>
    );
}

export default Banner;
