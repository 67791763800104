import React from 'react';
import './Mascot.css';
import sleeping from '../assets/mascot-images/sleeping.png';
import welcome from '../assets/mascot-images/welcome.png';
import eyesClosed from '../assets/mascot-images/eyesClosed.png';
import eyesPeeping from '../assets/mascot-images/eyesPeeping.png';
import reading from '../assets/mascot-images/reading.png';
import pendingBooks from '../assets/mascot-images/pendingBooks.png';

// Define a mapping of emotions to image paths
const emotionImages = {
  sleeping: sleeping,
  welcome: welcome,
  eyesClosed: eyesClosed,
  eyesPeeping: eyesPeeping,
  reading: reading,
  pendingBooks: pendingBooks,
};

const Mascot = ({ emotion }) => {
  // Get the image path based on the emotion prop
  const imagePath = emotionImages[emotion] || sleeping;

  return (
    <div className="mascot-container">
      <img src={imagePath} alt={emotion} className="mascot-image" />
    </div>
  );
};

export default Mascot;
