import React, {useEffect, useState} from 'react';
import instance from '../services/axios';
import requests from '../services/requests';
import BookshelfCard from './BookshelfCard';
import './BookListModal.css';

const BookListModal = ({ show, onClose, status }) => {
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);

    const convertHttpToHttps = (url) => {
        if (url && url.startsWith('http://')) {
          return url.replace('http://', 'https://');
        }
        return url;
      };

    const fetchBookShelf = async (page) => {
        setLoading(true);
        try {
            const response = await instance.post(requests.fetchBookShelf, {
                userId: localStorage.getItem('user'),
                status,
                limit: 10,
                offset: page * 10,
        });
            const newBooks = response.data.userBooks;

            if (newBooks.length < 10) {
                setHasMore(false); // No more books to load
            }

            setBooks((prevBooks) => [...prevBooks, ...newBooks]);
        } catch (error) {
            console.error('Failed to fetch user books:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    
        if (scrollHeight - scrollTop <= clientHeight + 20 && hasMore && !loading) {
          setPage((prevPage) => prevPage + 1);
        }
      };

      useEffect(() => {
        if (show) {
          fetchBookShelf(page);
        }
      }, [show, page]);

      useEffect(() => {
            setBooks([]);
            setPage(0);
            setHasMore(true);
      }, [show, status, onClose]);
    
      if (!show) {
        return null;
      }

  return (
    <div className="modal-overlay" onScroll={handleScroll}>
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <div className="modal-body" onScroll={handleScroll}>
        {books.map((item) => (
            <BookshelfCard
                key={item.BookId}
                product={item}
                id={item.BookId}
                photo={convertHttpToHttps(item.BookPhoto)}
                status={item.Status}
                startDate={item.StartDate}
                endDate={item.EndDate}
                currentPage={item.CurrentPage}
            />
        ))}
        {loading && <p className="loading-text">Loading more books...</p>}
        {!hasMore && <p>No more books to load.</p>}
    </div>
      </div>
    </div>
  );
};

export default BookListModal;
