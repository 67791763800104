import React, { useState, useContext, useEffect } from "react";

import instance from "../services/axios";
import requests from "../services/requests";

import MapComponent from "./MapComponent";

import { UserContext } from '../contexts/UserContext';

import "./OrderPage.css";

function OrderPage( {cancelOrder, bookName, bookImage, isRent, price} ) {
    const [name , setName] = useState('');
    const [phone , setPhone] = useState('');
    const [address , setAddress] = useState('');
    const [latLang, setLatLang] = useState('');
    const [orderDuration, setOrderDuration] = useState('1');
    const [paymentOption, setPaymentOption] = useState('');
    const [successMessageVisibility, setSuccessMessageVisibility] = useState(false); //sets visiblity of successmessage hidden
 
    const { user } = useContext(UserContext);

    // function to update state of name with
    // value enter by user in form
    const handleNameChange =(e)=>{
      setName(e.target.value);
    }
    // function to update state of phone with value
    // enter by user in form
    const handlePhoneChange =(e)=>{
      setPhone(e.target.value);
    }
    // function to update state of address with value
    // enter by user in form
    const handleAddressChange =(e)=>{
        setAddress(e.target.value);
      }

    const handleGeoLocation = (location) => {
        setLatLang(location);
      }

    const handleDurationChange = (e) => {
        setOrderDuration(e.target.value);
      }

    const handlePaymentOptionClick = (option) => {
        setPaymentOption(option);
    };

    const fetchUserData = () => {
        async function fetchData() {
            try {
                const response = await instance.post(requests.fetchUserData, {
                    userId: user
                  });
                if (response.data.message === 1)
                {
                    if (response.data.name) {
                        setName(response.data.name);
                    }
                    if (response.data.phone) {
                        setPhone(response.data.phone);
                    }
                    if (response.data.address) {
                        setAddress(response.data.address);
                    }
                }
                else
                {
                    //alert(response.data.message);
                }
              } catch (error) {
                console.log(error);
              }
        }
        fetchData();
    }

    async function placeOrder(paymentStatus) {
        let finalAmount = isRent ? price * orderDuration : price;

        if (isRent && finalAmount < 120) {
            finalAmount += 90;
        }
        try {
            const response = await instance.post(requests.placeOrder, {
                custId: user,
                custName: name,
                custPhone: phone,
                custAddress: address,
                custGeoLocation: latLang.lat+","+latLang.lng,
                orderedBook: bookName,
                custOrderDuration: orderDuration,
                orderImage: bookImage,
                amount: finalAmount,
                payment: paymentStatus,
                orderMode: isRent ? "Rent" : "Buy",
              });
            if (response.data.message === 1)
            {
                setSuccessMessageVisibility(true);
            }
            else
            {
                alert(response.data.message);
                console.log(response);
            }
          } catch (error) {
            console.log(error);
          }
    }

    const handleClick = () => {
        if (!name || !phone || !address || !paymentOption)
        {
            alert("Please fill in all the details and select a payment option.");
        } else if (paymentOption === "COD") {
            placeOrder(0);
        } else if (paymentOption === "Online") {
            
            handleOnlinePayment();
        }
    }

    const handleOnlinePayment = async () => {
        let finalAmount = isRent ? price * orderDuration : price;

        if (isRent && finalAmount < 120) {
            finalAmount += 90;
        }

        let link_id;
        let paymentWindow;

        try {
            const response = await instance.post(requests.paymentRequest, {
                customerName: name,
                customerPhone: phone,
                amount: finalAmount,
                });
            if (response.data && response.data.link_url) {
                paymentWindow = window.open(response.data.link_url, '_blank', 'width=600,height=600');
                link_id = response.data.link_id;

                const pollPaymentStatus = setInterval(async () => {
                try {
                    const statusResponse = await instance.post(
                        requests.paymentSuccessful + link_id,
                        {
                        customerId: user,
                        customerPhone: phone,
                        amount: finalAmount,
                        },
                    );
                    if (statusResponse.data.status === "success") {
                        clearInterval(pollPaymentStatus);
                        if (paymentWindow) {
                            paymentWindow.close();
                        }
                        placeOrder(1);
                    }
                } catch (error) {
                    console.error("Error occurred while checking payment status:", error);
                }
                }, 5000);
            } else {
                alert("Network error! Please try again.");
            }
        } catch (error) {
            console.error("Error occurred during payment:", error);
        }

    };

    useEffect(() => {
        fetchUserData();
    }, [])
    

    return (
        <div className="orderPageContainer">
                {successMessageVisibility ? (<h2 className="orderSuccessMessage">Thank you for the order! We will contact you soon.</h2>) : (
                    <><div className="leftAlign">
                    <div className="form">
                        <form>
                            <label>
                                Name:
                            </label><br />
                            <input type="text" value={name} required onChange={(e) => { handleNameChange(e); } } /><br />
                            {/*when user write in name input box , handleChange()
        function will be called. */}
                            <label>
                                Phone:
                            </label><br />
                            <input type="number" value={phone} required onChange={(e) => { handlePhoneChange(e); } } /><br />
                            {/* when user write in phone input box , handlePhoneChange()
        function will be called.*/}
                            <label>
                                Complete Address:
                            </label><br />
                            <textarea
                                rows={5} // Specifies the number of visible text lines
                                cols={40} // Specifies the width of the textarea in characters
                                wrap="soft" // Specifies how the text in the textarea should be wrapped
                                name="name" // Specifies the name of the textarea, which can be used when submitting a form
                                value={address}
                                required
                                onChange={(e) => { handleAddressChange(e); } } /><br />
                            {/* when user write in address input box , handleAddressChange()
        function will be called.*/}
                            {isRent && (
                                <>
                                    <label>
                                    Booking period:
                                    </label><br />
                                    <select onChange={(e) => { handleDurationChange(e); } }>
                                        <option value="1">1 week</option>
                                        <option value="2">2 weeks</option>
                                        <option value="3">3 weeks</option>
                                        <option value="4">4 weeks</option>
                                    </select>
                                </>
                            )}
                        </form>
                    </div>
                    <div className="buttons">
                        <button className="button" onClick={() => handleClick()}>Confirm order | ₹{isRent ? price * orderDuration : price}</button>
                        <button className="button" onClick={cancelOrder}>Cancel</button>
                    </div>
                </div><div className="rightAlign">
                    <div className="paymentButtons">
                        <button
                            className={`paymentOptionButton ${paymentOption === 'Online' ? 'selected' : ''}`}
                            onClick={() => handlePaymentOptionClick('Online')}
                        >
                            Pay Online
                        </button>
                        <button
                            className={`paymentOptionButton ${paymentOption === 'COD' ? 'selected' : ''}`}
                            onClick={() => handlePaymentOptionClick('COD')}
                        >
                            Cash on Delivery
                        </button>
                    </div>
                    {/* <MapComponent geoLocation={handleGeoLocation} /> */}
                    </div></>
                )}
                
        </div>
    )
}

export default OrderPage;