import React, { useState, useEffect } from 'react';
import Rating from 'react-rating-stars-component';
import axios from "../services/axios";
import requests from "../services/requests";

import "./ProductReviews.css";

function ProductReviews({ id, isGoogleBook, product }) {
    const [reviews, setReviews] = useState([]);
    const [userReview, setUserReview] = useState('');
    const [rating, setRating] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const reviewsLimit = 10;

    async function fetchReviews(newOffset = 0) {
        try {
            let bookIdToFetch = id;

            if (isGoogleBook) {
                // Fetch the BookId based on ISBN13 in case of google books
                const isbn = product.volumeInfo?.industryIdentifiers?.find(id => id.type === 'ISBN_13')?.identifier || '';
                if (isbn) {
                    const bookIdResponse = await axios.post(requests.fetchBookId, { ISBN: isbn });
                    if (bookIdResponse.data.bookId) {
                        bookIdToFetch = bookIdResponse.data.bookId;
                    } else {
                        console.log("Failed to fetch BookId");
                        return;
                    }
                }
            }

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const response = await axios(`${requests.fetchProductReviews}${bookIdToFetch}?offset=${newOffset}&limit=${reviewsLimit}&timezone=${userTimezone}`);
            const fetchedReviews = response.data;

            if (fetchedReviews.length < reviewsLimit) {
                setHasMore(false); 
            }
            setReviews(prevReviews => {
                const prevReviewIds = new Set(prevReviews.map(review => review.ratingId));
                const uniqueReviews = fetchedReviews.filter(review => !prevReviewIds.has(review.ratingId));
                return [...prevReviews, ...uniqueReviews];
            });
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    }

    useEffect(() => {
        fetchReviews(offset);
    }, [product]);

    const handleReviewSubmit = async () => {
        if (!userReview || rating === 0) {
            alert('Please provide a review and rating.');
            return;
        }

        try {
            const userId = localStorage.getItem("user");

            let bookId = id;

            if (isGoogleBook) {
                // Add the book first in own db case of external book
                const bookData = {
                    ISBN: product.volumeInfo?.industryIdentifiers?.find(id => id.type === 'ISBN_13')?.identifier || '',
                    Title: product.volumeInfo?.title || '',
                    Pages: product.volumeInfo?.pageCount || '',
                    Price: product.saleInfo?.listPrice?.amount || 0,
                    Description: product.volumeInfo?.description || '',
                    Authors: JSON.stringify(product.volumeInfo?.authors || []),
                    Genres: JSON.stringify(product.volumeInfo?.categories || []),
                    Image: product.volumeInfo?.imageLinks?.thumbnail || ''
                };
    
                const bookResponse = await axios.post(requests.addBook, bookData);
    
                if (bookResponse.data.message === "Book added/updated successfully") {
                    bookId = bookResponse.data.bookId;
                } else {
                    console.log("Failed to add/update book");
                    return;
                }
            }

            const reviewData = {
                userId: userId,
                productId: bookId,
                rating: rating,
                review: userReview,
            };

            const response = await axios.post(requests.submitReview, reviewData);
            if (response.data.message === "Updated") {
                setUserReview('');
                setRating(0);
                setOffset(0);
                setReviews([]);
                fetchReviews(); // Refresh reviews after submitting
            }
        } catch (error) {
            console.error('Error submitting review:', error);
        }
    };

    const handleLoadMore = () => {
        setOffset(prevOffset => {
            const newOffset = prevOffset + reviewsLimit;
            fetchReviews(newOffset);
            return newOffset;
        });
    };

    return (
        <div className="product-reviews-container">
            {localStorage.getItem('user') ? (
                <div className="review-form">
                    <div className="rating-input">
                        <label className="rating-label">Rating:</label>
                        <Rating
                            count={5}
                            size={30}
                            value={rating}
                            isHalf={true}
                            color={"#AEAEAE"}
                            activeColor={"#D17842"}
                            onChange={(newRating) => setRating(newRating)}
                        />
                    </div>
                    <textarea
                        rows={5}
                        cols={40}
                        wrap="soft"
                        value={userReview}
                        onChange={(e) => setUserReview(e.target.value)}
                        placeholder="Write your review here..."
                        className="review-textarea"
                    />
                    <button className="product-buttons review-button" onClick={handleReviewSubmit}>
                        Submit Review
                    </button>
                </div>
            ) : (
                <p className="login-prompt">Login to put a review.</p>
            )}
            <div className="reviews-list">
                {reviews.map((review) => (
                    <div key={review.id} className="review">
                        <p className="review-author">By {review.userName}</p>
                        <p className="review-date">{review.ratingDate}</p>
                        <p className="review-rating">
                            <Rating 
                                value={review.rating} 
                                count={5}
                                size={30}
                                isHalf={true}
                                color={"#AEAEAE"}
                                activeColor={"#D17842"}
                            /> {review.rating}      
                        </p>
                        <p className="review-text">{review.review}</p>
                    </div>
                ))}
                {hasMore && (
                    <button className="product-buttons review-button" onClick={handleLoadMore}>
                        Load More
                    </button>
                )}
                {!hasMore && <p className="no-more-reviews-text">No more reviews to show.</p>}
            </div>
        </div>
    );
}

export default ProductReviews;