import React, { useState, useEffect, useRef } from 'react';
import instance from '../../services/axios';
import requests from '../../services/requests';
import './DurationTrackerList.css';
import SEO from '../../services/seo';
import Mascot from '../Mascot';

const DurationTrackerList = () => {
    const [durations, setDurations] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observerRef = useRef();

    const userId = localStorage.getItem('user');
    const listLimit = 10;

    useEffect(() => {
        const fetchList = async () => {
            try {
                // const userTimezone = 'Asia/Kolkata'; // Replace this with actual timezone logic
                const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const response = await instance.get(`${requests.fetchReadingDurations}`, {
                    params: {
                        userId,
                        offset,
                        limit: listLimit,
                        timezone: userTimezone,
                    },
                });
                const fetchedList = response.data;
                if (fetchedList.length < listLimit) {
                    setHasMore(false);
                }
                setDurations(prevDurations => {
                    const prevDurationIds = new Set(prevDurations.map(duration => duration.readingDurationId));
                    const uniqueDurations = fetchedList.filter(duration => !prevDurationIds.has(duration.readingDurationId));
                    return [...prevDurations, ...uniqueDurations];
                });
            } catch (error) {
                console.error("Error fetching reading sessions:", error);
            }
        };

        fetchList();
    }, [offset, userId]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    setOffset(prevOffset => prevOffset + listLimit);
                }
            },
            { threshold: 1.0 }
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasMore]);

    return (
        <>
            <SEO
                title="Your reading logs - Biblophile"
                description="View the reading logs you have saved."
                name='Biblophile'
                type='website'
                image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
            />
            <div className="user-durations">
                <h2 className="title">My Reading Sessions</h2>
                {durations.length === 0 && 
                <div className="empty-list-container">
                    <Mascot emotion="reading"/>
                    <p className="mascot-subtitle">Use our smart bookmarks to save reading logs</p>
                </div>
                }
                {durations.map((duration) => (
                    <div key={duration.readingDurationId} className="duration-card">
                        <div className="duration-content">
                            <div className="duration-text-container">
                                <p className="duration-text">{duration.note}</p>
                                <p className="updatedAt-text">{duration.updatedAt}</p>
                            </div>
                        </div>
                    </div>
                ))}
                {hasMore && <div ref={observerRef} className="loading">Loading more sessions...</div>}
            </div>
        </>
    );
};

export default DurationTrackerList;