const ACTION_URL = process.env.REACT_APP_ACTION_URL;
const USER_INSIGHT_URL = process.env.REACT_APP_USER_INSIGHT_URL;

const requests = {
    //request to biblophile actions api
    placeOrder: `${ACTION_URL}?action=placeOrder`,
    userLogin: `${ACTION_URL}?action=userLogin`,
    userSignup: `${ACTION_URL}?action=userSignup`,
    forgotPassword: `${ACTION_URL}?action=forgotPassword`,
    fetchUserData: `${ACTION_URL}?action=fetchUserData`,
    fetchOrders: `${ACTION_URL}?action=fetchOrders`,
    updateUserData: `${ACTION_URL}?action=updateUserData`,
    fetchDeposit: `${ACTION_URL}?action=fetchDeposit`,
    getBookGenre:`${ACTION_URL}?action=getBookGenre`,
    getBooks: `${ACTION_URL}?action=getBooks&bookGenre=`,
    searchBooks: `${ACTION_URL}?action=searchBooks&searchQuery=`,
    searchExternalBooks: `${ACTION_URL}?action=searchExternalBooks&searchQuery=`,
    getBookmarks: `${ACTION_URL}?action=getBookmarks`,
    fetchProductDetails:`${ACTION_URL}?action=fetchProductDetails&id=`,
    fetchExternalBookDetails:`${ACTION_URL}?action=fetchExternalBookDetails&id=`,
    fetchAverageRating:`${ACTION_URL}?action=fetchAverageRating&id=`,
    fetchAverageEmotions:`${ACTION_URL}?action=fetchAverageEmotions&id=`,
    fetchProductReviews:`${ACTION_URL}?action=fetchProductReviews&id=`,
    fetchEmotions:`${ACTION_URL}?action=fetchEmotions`,
    fetchReadingStatus:`${ACTION_URL}?action=fetchReadingStatus`,
    fetchUserBooks: `${ACTION_URL}?action=fetchUserBooks`,
    fetchCurrentReads: `${ACTION_URL}?action=fetchCurrentReads`,
    updateBookDates: `${ACTION_URL}?action=updateBookDates`,
    submitReadingStatus: `${ACTION_URL}?action=submitReadingStatus`,
    submitReview: `${ACTION_URL}?action=submitReview`,
    addBook: `${ACTION_URL}?action=addBook`,
    fetchBookId:`${ACTION_URL}?action=fetchBookId`,
    submitEmotionScore: `${ACTION_URL}?action=submitEmotionScore`,
    fetchActivePlan: `${ACTION_URL}?action=fetchActivePlan&userId=`,
    fetchSubscriptionPlans: `${ACTION_URL}?action=fetchSubscriptionPlans`,
    paymentRequest: `${ACTION_URL}?action=paymentRequest`,
    paymentSuccessful: `${ACTION_URL}?action=paymentSuccessful&linkId=`,

    //request to biblophile user-insights api
    fetchAverageRatingByUser:`${USER_INSIGHT_URL}?action=fetchAverageRatingByUser&userId=`,
    fetchAverageEmotionsByUser:`${USER_INSIGHT_URL}?action=fetchAverageEmotionsByUser&userId=`,
    fetchAverageDaystoFinish: `${USER_INSIGHT_URL}?action=fetchAverageDaystoFinish&userId=`,
    fetchUserReviews: `${USER_INSIGHT_URL}?action=fetchUserReviews&userId=`,
    updateUserReview: `${USER_INSIGHT_URL}?action=updateUserReview`,
    fetchBookShelf: `${USER_INSIGHT_URL}?action=fetchBookShelf`,
    fetchUserNotes: `${USER_INSIGHT_URL}?action=fetchUserNotes&userId=`,
    updateUserNote: `${USER_INSIGHT_URL}?action=updateUserNote`,
    fetchReadingDurations: `${USER_INSIGHT_URL}?action=fetchReadingDurations&userId=`,
}

export default requests;