import React,{ useContext, useState, useEffect, useRef } from "react";
import axios from "../../services/axios";
import requests from "../../services/requests";
import SEO from "../../services/seo";

import { UserContext } from '../../contexts/UserContext';

import OrderDetail from "../OrderDetail";
import Mascot from "../Mascot";

import "./Orders.css";

function Orders() {
  const[orders, setOrders] = useState( [] );
  const [deposit, setDeposit] = useState('');
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef();

  const {user, setUser} = useContext(UserContext);
  const ordersLimit = 10;

    useEffect( () =>{
      async function fetchData() {
          try {
            const response = await axios.post(`${requests.fetchOrders}&offset=${offset}&limit=${ordersLimit}`, {
              userId: user
            });
            const fetchedOrders = response.data;
                if (fetchedOrders.length < ordersLimit) {
                    setHasMore(false);
                }
                setOrders(prevOrders => {
                    const prevOrderIds = new Set(prevOrders.map(order => order.OrderId));
                    const uniqueOrders = fetchedOrders.filter(order => !prevOrderIds.has(order.OrderId));
                    return [...prevOrders, ...uniqueOrders];
                });
            } catch (error) {
              console.log(error);
            }
      }
      async function fetchDeposit() {
        try {
          const response = await axios.post(requests.fetchDeposit, {
            userId: user
          });
          setDeposit(response.data.deposit);
          } catch (error) {
            console.log(error);
          }
    }
      fetchData();
      fetchDeposit();
    }, []);

    useEffect(() => {
      const observer = new IntersectionObserver(
          entries => {
              if (entries[0].isIntersecting && hasMore) {
                  setOffset(prevOffset => prevOffset + ordersLimit);
              }
          },
          { threshold: 1.0 }
      );

      if (observerRef.current) {
          observer.observe(observerRef.current);
      }

      return () => {
          if (observerRef.current) {
              observer.unobserve(observerRef.current);
          }
      };
  }, [hasMore]);

    return (
      <div className="orders">
        <SEO
          title="Your Orders - Biblophile"
          description="View your orders and security deposit details on Biblophile."
          name='Biblophile'
          type='article'
          image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
        />
        <h1>Your orders</h1>
        <h4>Security deposit: ₹{deposit}</h4>
        {orders.length > 0 ?
          orders.map(order => (
            <OrderDetail item = {order} /> 
        )):
        <Mascot emotion="sleeping"/>
        }
        {hasMore && <div ref={observerRef} className="loading">Loading more orders...</div>}
      </div>
    );
  }
  
  export default Orders;