import React, { useEffect, useState } from 'react';
import instance from '../../services/axios';
import requests from '../../services/requests';
import './Bookshelf.css';
import BookshelfCard from '../BookshelfCard';
import SEO from '../../services/seo';
import Mascot from '../Mascot';
import BookListModal from '../BookListModal';

const BookshelfScreen = () => {
  const [userBooks, setUserBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalStatus, setModalStatus] = useState('');

  const fetchUserBooks = async () => {
    try {
      const response = await instance.post(requests.fetchUserBooks, {
        userId: localStorage.getItem('user'),
      });
      setUserBooks(response.data.userBooks);
    } catch (error) {
      console.error('Failed to fetch user books:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserBooks();
  }, []);

  const convertHttpToHttps = (url) => {
    if (url && url.startsWith('http://')) {
      return url.replace('http://', 'https://');
    }
    return url;
  };

  const renderBooksByStatus = (status) => {
    const books = userBooks.filter((book) => book.Status === status);
    if (books.length === 0) {
      return null;
    }

    const openModal = (status) => () => {
      setModalVisible(true);
      setModalStatus(status);
    };

    return (
      <div className="section-container">
        <div className="section-header">
          <h2 className="section-title">{status}</h2>
          <span className="show-more" onClick={openModal(status)}>
            Show more
          </span>
        </div>
        <ul className="bookshelf-container">
          {books.map((item) => (
            <BookshelfCard
              key={item.BookId}
              product={item}
              id={item.BookId}
              photo={convertHttpToHttps(item.BookPhoto)}
              status={item.Status}
              startDate={item.StartDate}
              endDate={item.EndDate}
              currentPage={item.CurrentPage}
            />
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <SEO
          title="Bookshelf - Biblophile"
          description="View the books you have marked as read, currently reading, to be read or did not finish."
          name='Biblophile'
          type='website'
          image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
        />
      <div className="screen-container">

        {loading ? (
          <p className="loading-text">Loading...</p>
        ) : userBooks.length === 0 ? (
          <Mascot emotion="reading"/> 
        ) : (
          <>
            {renderBooksByStatus('Currently reading')}
            {renderBooksByStatus('Read')}
            {renderBooksByStatus('Paused')}
            {renderBooksByStatus('To be read')}
            {renderBooksByStatus('Did not finish')}
          </>
        )}
      </div>
      <BookListModal show={modalVisible} onClose={() => setModalVisible(false)} status={modalStatus} />
    </>
  );
};

export default BookshelfScreen;
