import React, { useState,useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import { UserContext } from './contexts/UserContext';

import './App.css';

import SignupLogin from './components/SignupLogin';
import Nav from './components/Nav';
import Row from './components/Row';
import Footer from './components/Footer';

import Home from './components/body/Home';
import Orders from './components/body/Orders';
import About from './components/body/About';
import Settings from './components/body/Settings';
import ProductDetailsPage from './components/body/ProductDetailsPage';
import BookshelfScreen from './components/body/Bookshelf';
import BottomTab from './components/BottomTab';
import ProfilePage from './components/body/ProfilePage';
import SubscriptionPage from './components/body/SubscriptionPage';
import ReviewList from './components/body/ReviewList';
import NoteList from './components/body/NoteList';
import DurationTrackerList from './components/body/DurationTrackerList';

function App() {
  const { user, setUser } = useContext(UserContext);
  const [searchVisibility, setSearchVisibility] = useState(false);
  const [searchedQuery, setSearchedQuery] = useState('');

  const handleSearchVisibility = (query) => {
    //display searched result only if the query has atleast 3 letters
    setSearchedQuery(query);
    if (query.length > 1) {
      setSearchVisibility(true);
    }
    else {
      setSearchVisibility(false);
    }
  }

  const handleResetSearch = () => {
    setSearchVisibility(false);
    setSearchedQuery('');
  };

  useEffect(() => {
    if (!user) {
      const loggedInUser = localStorage.getItem("user");
      if (loggedInUser) {
        setUser(loggedInUser);
      }
    }
  }, [user, setUser]);
  
  if (user !== null) {  //when the user access homepage with or without login
    return (
      <HelmetProvider>
        <div className="app">
          <Router>
            <Nav searchParam = {handleSearchVisibility}/>

            {/* if nav passes searched term then show only searched row otherwise show banner and other rows */}

            {searchVisibility ? (
              <>
              <Row className="search_row" title="Available results" searchQuery={searchedQuery} onResetSearch={handleResetSearch} />
              <Row className="search_row" title="Other results" searchQuery={searchedQuery} onResetSearch={handleResetSearch} />
              </>
            ) : (
              <div className="main_body"> 
                <Routes>
                  <Route path="/" Component={Home} />
                  <Route path="/orders" Component={Orders} />
                  <Route path="/profile" Component={ProfilePage} />
                  <Route path="/bookshelf" Component={BookshelfScreen} />
                  <Route path="/subscription" Component={SubscriptionPage} />
                  <Route path="/reviews" Component={ReviewList} />
                  <Route path="/notes" Component={NoteList} />
                  <Route path="/sessions" Component={DurationTrackerList} />
                  <Route path="/about" Component={About} />
                  <Route path="/settings" Component={Settings} />
                  <Route path="/product" Component={ProductDetailsPage} />
                </Routes>
              </div>
            )}

            <Footer />
            {(user !== 0) && <BottomTab />}
          </Router>
        </div>
      </HelmetProvider>
    );
  }
  else {  //At the signuplogin page
    return (
      <HelmetProvider>
        <div className="app">
          <div className='signupLogin'>
            <SignupLogin />
          </div>
          <Router>
            <Nav searchParam = {handleSearchVisibility} />
            <Footer />
          </Router>
        </div>
      </HelmetProvider>
    );
  }

}

export default App;
