import React, { useState, useEffect, useRef } from 'react';
import instance from '../../services/axios';
import requests from '../../services/requests';
import './ReviewList.css';
import SEO from '../../services/seo';
import Mascot from '../Mascot';

const ReviewList = () => {
    const [reviews, setReviews] = useState([]);
    const [editing, setEditing] = useState(null);
    const [currentReview, setCurrentReview] = useState({ rating: '', review: '' });
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const observerRef = useRef();

    const userId = localStorage.getItem('user');
    const reviewsLimit = 10;

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const response = await instance.get(`${requests.fetchUserReviews}`, {
                    params: {
                        userId,
                        offset,
                        limit: reviewsLimit,
                        timezone: userTimezone,
                    },
                });
                const fetchedReviews = response.data;
                if (fetchedReviews.length < reviewsLimit) {
                    setHasMore(false);
                }
                setReviews(prevReviews => {
                    const prevReviewIds = new Set(prevReviews.map(review => review.ratingId));
                    const uniqueReviews = fetchedReviews.filter(review => !prevReviewIds.has(review.ratingId));
                    return [...prevReviews, ...uniqueReviews];
                });
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        };

        fetchReviews();
    }, [offset, userId]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    setOffset(prevOffset => prevOffset + reviewsLimit);
                }
            },
            { threshold: 1.0 }
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasMore]);

    const handleEdit = (review) => {
        setEditing(review.ratingId);
        setCurrentReview({ rating: review.rating, review: review.review });
    };

    const handleDelete = (ratingId, productId) => {
        const confirmed = window.confirm("Are you sure you want to delete this review?");
        if (confirmed) {
            instance.post(requests.updateUserReview, {
                userId,
                productId,
                actionType: 'delete'
            })
                .then(response => {
                    setReviews(reviews.filter(review => review.ratingId !== ratingId));
                    alert(response.data.message);
                })
                .catch(error => console.error("Error deleting review:", error));
        }
    };

    const handleSave = (ratingId, productId) => {
        instance.post(requests.updateUserReview, {
            userId,
            productId,
            rating: currentReview.rating,
            review: currentReview.review,
            actionType: 'update'
        })
            .then(response => {
                setReviews(reviews.map(review =>
                    review.ratingId === ratingId ? { ...review, rating: currentReview.rating, review: currentReview.review } : review
                ));
                setEditing(null);
                alert(response.data.message);
            })
            .catch(error => console.error("Error updating review:", error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentReview(prev => ({ ...prev, [name]: value }));
    };

    return (
        <>
            <SEO
                title="Your reviews - Biblophile"
                description="View the ratings and reviews you have written for the books."
                name='Biblophile'
                type='website'
                image='https://i.ibb.co/HdqFMq2/biblophile-og-img.png'
            />
            <div className="user-reviews">
                <h2 className="title">My Ratings & Reviews</h2>
                {reviews.length === 0 && <Mascot emotion="reading"/>}
                {reviews.map((review) => (
                    <div key={review.ratingId} className="review-card">
                        <img src={review.bookImage} alt={`${review.bookTitle} cover`} className="book-image" />
                        {editing === review.ratingId ? (
                            <>
                                <input
                                    type="number"
                                    name="rating"
                                    value={currentReview.rating}
                                    onChange={handleChange}
                                    className="rating-input"
                                    min="1"
                                    max="5"
                                />
                                <textarea
                                    name="review"
                                    value={currentReview.review}
                                    onChange={handleChange}
                                    className="review-input"
                                />
                                <button onClick={() => handleSave(review.ratingId, review.productId)} className="save-btn">Save</button>
                            </>
                        ) : (
                            <>
                                <div className="rating">Rating: {review.rating} / 5</div>
                                <p className="review-text">{review.review}</p>
                                <button onClick={() => handleEdit(review)} className="edit-btn">Edit</button>
                                <button onClick={() => handleDelete(review.ratingId, review.productId)} className="delete-btn">Delete</button>
                            </>
                        )}
                    </div>
                ))}
                {hasMore && <div ref={observerRef} className="loading">Loading more reviews...</div>}
            </div>
        </>
    );
};

export default ReviewList;